<template>
  <div class="bankinfo-wrap">
    <div class="tmp-title bankinfo_tips">一般情况下如果无中转行，请按照以下格式填写，每一项都是必填。</div>
    <div class="tmp-content">
      <div>银行名称：BANCO ITAU UNIBANCO S.A.</div>
      <div>SWIFT CODE：ITAUBRSP</div>
      <div>银行地址：CAT FLOOR 2 RUA URURAI 111 PREDIO B</div>
      <div>收款人账号：BR4360701190037370000132112C1</div>
      <div>收款人名称：AGROINDUSTRIAL IGUATEMI EIRELI</div>
      <div>收款人地址：ROD.IGUATEMI/SETE QUEDAS KM 1,5 MATO GROSSO DO SUL-BRAZIL</div>
    </div>
    <div class="tmp-title bankinfo_tips" style="margin-top: 30px">如果有中转行(intermediary bank)，请按照以下格式填写，每一项都是必填。</div>
    <div class="tmp-content">
      <div>收款银行之代理行信息</div>
      <div>银行名称：Wachovia Bank</div>
      <div style="margin-bottom: 24px">SWIFT CODE：PNBPUS3NNYC</div>
      <div>收款银行信息</div>
      <div>银行名称：BANCO ITAU UNIBANCO S.A.</div>
      <div>SWIFT CODE：ITAUBRSP</div>
      <div>银行地址：CAT FLOOR 2 RUA URURAI 111 PREDIO B</div>
      <div>收款人账号：BR4360701190037370000132112C1</div>
      <div>收款人名称：AGROINDUSTRIAL IGUATEMI EIRELI</div>
      <div>收款人地址：ROD.IGUATEMI/SETE QUEDAS KM 1,5 MATO GROSSO DO SUL-BRAZIL</div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.bankinfo-wrap {
  padding: 30px 20px;
  color: #606266;
  font-size: 14px;
  border-top: 1px solid #e3e4e5;
  .tmp-title {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #666;
    margin-bottom: 12px;
    img {
      margin-right: 7px;
    }
  }
  .tmp-content {
    background: #f5f5f5;
    border: 1px solid #ebeef5;
    padding: 21px;
    > div {
      margin-bottom: 8px;
      font-size: 14px;
      color: #1a1a1a;
      font-weight: 400;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
