
import CrmList from '@/components/CrmList/CrmList.vue';
import useListData from './components/useListData';
import useVisible from '@/hooks/useVisible';
import SupplierForm from './components/SupplierForm/SupplierForm.vue';
import { defineComponent, getCurrentInstance, ref } from 'vue';
import ErpDrawer from '@/components/ErpDrawer/ErpDrawer.vue';
import { errorMessage, hasPermission, loadPage } from '@/utils/publicMethods';
export default defineComponent({
  name: 'SupplierList',
  components: { CrmList, SupplierForm, ErpDrawer },
  setup() {
    const instance = getCurrentInstance() as any;
    const { filterOptions, columnList, tableRef } = useListData(instance);
    const { visible, showVisible, setFormType, formType } = useVisible();
    const rowData = ref({});
    const refreshTable = (status = false) => {
      tableRef.value.refreshTable(status);
    };
    const rowClick = (row, column) => {
      console.log('row', row);

      if (column.label !== '操作') {
        loadPage('SupplierDetails', {
          id: row.id,
          isRecycle: 'false',
          type: '1',
          backFrom: '/basicInformation/supplier',
        });
      }
    };
    const closeVisible = () => {
      visible.value = false;
      refreshTable();
    };
    // 打开新建供应商弹窗
    const openFormDialog = (permission) => {
      if (hasPermission(permission)) {
        setFormType(1);
        showVisible();
      } else {
        errorMessage('没有权限');
      }
    };
    return {
      filterOptions,
      openFormDialog,
      columnList,
      tableRef,
      visible,
      showVisible,
      closeVisible,
      refreshTable,
      rowData,
      setFormType,
      formType,
      rowClick,
    };
  },
});
