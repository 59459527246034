import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-20a78e61"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mali-edit__form" }
const _hoisted_2 = { class: "flex-box" }
const _hoisted_3 = { class: "mali-edit__form" }
const _hoisted_4 = { class: "mali-edit__form" }
const _hoisted_5 = { class: "mali-edit__form" }
const _hoisted_6 = { class: "crm-button__group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_ChooseMan = _resolveComponent("ChooseMan")!
  const _component_DictSelect = _resolveComponent("DictSelect")!
  const _component_CheckBoxForm = _resolveComponent("CheckBoxForm")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_CrmFormLayout = _resolveComponent("CrmFormLayout")!
  const _component_BankInfoTips = _resolveComponent("BankInfoTips")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_CrmFormLayout, {
      title: _ctx.setTitleLabel(_ctx.formType, ['新建', '编辑']) + '供应商',
      onClose: _ctx.close
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          model: _ctx.formData,
          "label-position": "top",
          ref: "formRef",
          rules: _ctx.rules
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_el_row, { gutter: 80 }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_form_item, {
                        label: "供应商名称",
                        prop: "supplierName",
                        required: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_input, {
                            modelValue: _ctx.formData.supplierName,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.supplierName) = $event)),
                            maxlength: 100,
                            clearable: "",
                            placeholder: "请输入供应商名称"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_form_item, {
                        label: "负责人",
                        prop: "principalIds"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_2, [
                            _createVNode(_component_ChooseMan, {
                              style: {"width":"420px"},
                              modelValue: _ctx.formData.principalIds,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.principalIds) = $event)),
                              name: _ctx.formData.principals,
                              "onUpdate:name": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.principals) = $event)),
                              defaultCreated: _ctx.formType === 1,
                              limt: 5,
                              ref: "principalRef",
                              placeholder: "请选择负责人",
                              title: "请选择负责人",
                              selectAll: true
                            }, null, 8, ["modelValue", "name", "defaultCreated"])
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_form_item, {
                        label: "国家",
                        prop: "country"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_DictSelect, {
                            modelValue: _ctx.formData.country,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.country) = $event)),
                            name: "goods_country",
                            placeholder: "请选择国家",
                            disabled: _ctx.formType === 3 || _ctx.formType === 4
                          }, null, 8, ["modelValue", "disabled"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_form_item, {
                        label: "出口备案记录号",
                        prop: "exportRecordNo"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_input, {
                            modelValue: _ctx.formData.exportRecordNo,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.exportRecordNo) = $event)),
                            maxlength: 11,
                            clearable: "",
                            onBlur: _ctx.changeNumber
                          }, null, 8, ["modelValue", "onBlur"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_form_item, {
                        label: "公司类型",
                        prop: "supplierCompanyTypeDisplay"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_CheckBoxForm, {
                            modelValue: _ctx.formData.supplierCompanyTypeDisplay,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formData.supplierCompanyTypeDisplay) = $event)),
                            type: "company",
                            onSure: _ctx.checkboxSure,
                            values: _ctx.formData.supplierCompanyTypeVos,
                            placeholder: "请选择公司类型"
                          }, null, 8, ["modelValue", "onSure", "values"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_form_item, {
                        label: "公司成立时间",
                        prop: "establishedTime"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_date_picker, {
                            "prefix-icon": "null",
                            modelValue: _ctx.formData.establishedTime,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formData.establishedTime) = $event)),
                            type: "year",
                            placeholder: "请选择公司成立时间"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_form_item, {
                        label: "主营业务",
                        prop: "supplierBusinessTypeDisplay"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_CheckBoxForm, {
                            modelValue: _ctx.formData.supplierBusinessTypeDisplay,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formData.supplierBusinessTypeDisplay) = $event)),
                            type: "business",
                            onSure: _ctx.checkboxSure,
                            values: _ctx.formData.supplierBusinessTypeVos,
                            placeholder: "请选择主营业务"
                          }, null, 8, ["modelValue", "onSure", "values"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_form_item, {
                        label: "公司资质",
                        prop: "supplierQualificationTypeDisplay"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_CheckBoxForm, {
                            modelValue: _ctx.formData.supplierQualificationTypeDisplay,
                            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.formData.supplierQualificationTypeDisplay) = $event)),
                            type: "qualification",
                            onSure: _ctx.checkboxSure,
                            values: _ctx.formData.supplierQualificationTypeVos,
                            placeholder: "请选择公司资质"
                          }, null, 8, ["modelValue", "onSure", "values"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_form_item, {
                        label: "官方网站",
                        prop: "officialWebsite"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_input, {
                            maxlength: 50,
                            placeholder: "请输入官方网站",
                            clearable: "",
                            modelValue: _ctx.formData.officialWebsite,
                            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.formData.officialWebsite) = $event))
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_el_row, { gutter: 80 }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_form_item, {
                        label: "公司地址",
                        prop: "companyAddress"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_input, {
                            placeholder: "请输入公司地址",
                            modelValue: _ctx.formData.companyAddress,
                            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.formData.companyAddress) = $event)),
                            maxlength: 500,
                            clearable: "",
                            class: "mali-full__input11",
                            type: "textarea",
                            resize: "none"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_el_row, { gutter: 80 }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_form_item, {
                        label: "供应商备注",
                        prop: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_input, {
                            modelValue: _ctx.formData.remark,
                            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.formData.remark) = $event)),
                            placeholder: "",
                            maxlength: 300,
                            type: "textarea",
                            resize: "none",
                            rows: 5
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_el_row, { gutter: 80 }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_form_item, {
                        label: "银行信息",
                        prop: ""
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", {
                            class: "form-item__positon__label bankinfo_tips",
                            onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.showVisible()))
                          }, "请参考银行信息填写规范"),
                          _createVNode(_component_el_input, {
                            placeholder: "请输入银行信息",
                            modelValue: _ctx.formData.bankInfo,
                            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.formData.bankInfo) = $event)),
                            maxlength: 1000,
                            clearable: "",
                            class: "mali-full__input11 h88",
                            type: "textarea",
                            resize: "none"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }, 8, ["model", "rules"]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("button", {
            class: "crm-form__button primary",
            onClick: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.saveFormData && _ctx.saveFormData(...args)))
          }, "保存"),
          _createElementVNode("button", {
            class: "crm-form__button cancel",
            onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.close(false)))
          }, "取消")
        ])
      ]),
      _: 1
    }, 8, ["title", "onClose"]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.visible,
      "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.visible) = $event)),
      title: "外商银行信息填写范例",
      "append-to-body": true,
      width: "680px",
      onClose: _ctx.closeVisible
    }, {
      default: _withCtx(() => [
        _createVNode(_component_BankInfoTips)
      ]),
      _: 1
    }, 8, ["modelValue", "onClose"])
  ], 64))
}