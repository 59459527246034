import { reactive, ref, toRefs } from 'vue';

export default () => {
  const formRef = ref(); // 表单校验对象使用
  const formData = reactive({
    bankInfo: null,
    companyAddress: null,
    country: null,
    establishedTime: null,
    exportRecordNo: null,
    id: null,
    officialWebsite: null,
    principalIds: [],
    remark: null,
    supplierBusinessType: [],
    supplierBusinessTypeDisplay: null,
    supplierBusinessTypeVos: [],
    supplierCompanyType: [],
    supplierCompanyTypeDisplay: null,
    supplierCompanyTypeVos: [],
    supplierName: null,
    supplierQualificationType: [],
    supplierQualificationTypeVos: [],
    supplierQualificationTypeDisplay: null,
    version: null,
  });
  const rules = reactive({
    supplierName: [
      {
        required: true,
        message: '请输入供应商名称',
        trigger: 'blur',
      },
    ],
    principalIds: [
      {
        required: true,
        message: '请选择负责人',
        trigger: 'blur',
      },
    ],
    country: [
      {
        required: true,
        message: '请选择国家',
        trigger: 'change',
      },
    ],
    exportRecordNo: [
      {
        required: true,
        message: '请输入出口备案记录号',
        trigger: 'blur',
      },
    ],
    supplierCompanyTypeDisplay: [
      {
        required: true,
        message: '请选择公司类型',
        trigger: 'change',
      },
    ],
  });
  const customFormData = reactive({
    supplierCompanyItemArr: [
      { "typeCategory": 1, "typeValue": 1, "remark": null, "label": '生产商' }, // eslint-disable-line
      { "typeCategory": 1, "typeValue": 2, "remark": null, "label": '贸易商' }, // eslint-disable-line
      { "typeCategory": 1, "typeValue": 3, "remark": null, "label": '牧场' }, // eslint-disable-line
      { "typeCategory": 1, "typeValue": 4, "remark": null, "label": '渔场' }, // eslint-disable-line
      { "typeCategory": 1, "typeValue": 5, "remark": null, "label": '其他' } // eslint-disable-line
    ],
    supplierVisible: false,
    supplierCompanyTypeVos: [],
    supplierBusinessItemArr: [
      { "typeCategory": 2, "typeValue": 1, "remark": null, "label": '牛肉' }, // eslint-disable-line
      { "typeCategory": 2, "typeValue": 2, "remark": null, "label": '羊肉' }, // eslint-disable-line
      { "typeCategory": 2, "typeValue": 3, "remark": null, "label": '鸡肉' }, // eslint-disable-line
      { "typeCategory": 2, "typeValue": 4, "remark": null, "label": '猪肉' }, // eslint-disable-line
      { "typeCategory": 2, "typeValue": 5, "remark": null, "label": '水产' }, // eslint-disable-line
      { "typeCategory": 2, "typeValue": 6, "remark": null, "label": '其他' } // eslint-disable-line
    ],
    supplierBusinessVisible: false,
    supplierBusinessTypeVos: [],
    supplierQualificationItemArr: [
      { "typeCategory": 3, "typeValue": 1, "remark": null, "label": 'OHSAS 18001' }, // eslint-disable-line
      { "typeCategory": 3, "typeValue": 2, "remark": null, "label": 'ISO9001' }, // eslint-disable-line
      { "typeCategory": 3, "typeValue": 3, "remark": null, "label": 'ISO14001' }, // eslint-disable-line
      { "typeCategory": 3, "typeValue": 4, "remark": null, "label": 'HACCP' }, // eslint-disable-line
      { "typeCategory": 3, "typeValue": 5, "remark": null, "label": 'Halal' }, // eslint-disable-line
      { "typeCategory": 3, "typeValue": 6, "remark": null, "label": 'BRC' }, // eslint-disable-line
      { "typeCategory": 3, "typeValue": 7, "remark": null, "label": 'Other' } // eslint-disable-line
    ],
    supplierQualificationVisible: false,
    supplierQualificationTypeVos: [],
  });
  const setFormData = (paramsData: any) => {
    for (const key in paramsData) {
      if (Object.prototype.hasOwnProperty.call(paramsData, key)) {
        formData[key] = paramsData[key];
      }
    }
  };
  return { rules, formData, formRef, setFormData, ...toRefs(customFormData) };
};
