
import { httpGet, httpPost } from '@/api';
import ChooseMan from '@/components/ChooseMan/ChooseMan.vue';
import { ajaxLoading, errorMessage, setTitleLabel, successMessage } from '@/utils/publicMethods';
import { defineComponent, ref } from 'vue';
import useFormData from './useFormData';
import CheckBoxForm from './CheckBoxForm.vue';
import BankInfoTips from './BankInfoTips.vue';
import useVisible from '@/hooks/useVisible';

export default defineComponent({
  components: { ChooseMan, CheckBoxForm, BankInfoTips },
  props: {
    /** 新增编辑表单状态 1新增 2编辑 */
    formType: {
      type: [Number],
      default: 1,
    },
    data: {
      type: [Object],
    },
  },
  emits: ['close'],
  setup(props, ctx) {
    const propData = (props.data as any).value;
    const { visible, showVisible, closeVisible } = useVisible();
    const { rules, formData, formRef, setFormData } = useFormData();
    // 部门组件对象
    const principalRef = ref();
    // 获取详情
    const checkboxSure = (data, type) => {
      if (type === 'business') {
        formData.supplierBusinessType = data;
      }
      if (type === 'company') {
        formData.supplierCompanyType = data;
      }
      if (type === 'qualification') {
        formData.supplierQualificationType = data;
      }
    };

    const getInitData = async (id) => {
      const res = await httpGet(`/malicrm/supplier/supplierDetail/${id}`);
      if (res.code === 200) {
        setFormData(res.result);
        formData.supplierCompanyType = formData.supplierCompanyTypeVos;
        formData.supplierBusinessType = formData.supplierBusinessTypeVos;
        formData.supplierQualificationType = formData.supplierQualificationTypeVos;
      }
    };
    const close = (status = false) => {
      ctx.emit('close', status);
    };
    const submitData = async () => {
      ajaxLoading.lock();
      // todo
      const res = await httpPost('/malicrm/supplier/operateSupplier', formData);
      if (res.code === 200) {
        ajaxLoading.unLock();
        if (props.formType === 1) {
          successMessage('保存成功', 2000);
        } else {
          successMessage('编辑成功', 2000);
        }
        // 这里的解锁在页面返回解除掉，这样可以保证一定不会重复提交
        close(true);
      } else {
        errorMessage(res.message);
        ajaxLoading.unLock();
      }
    };
    /** 提交表单 */
    const saveFormData = async () => {
      formRef.value.validate((valid) => {
        if (valid) {
          submitData();
        } else {
          return false;
        }
      });
    };
    if (props.formType === 2) {
      getInitData((propData as any).id);
    }
    return {
      close,
      formData,
      saveFormData,
      rules,
      formRef,
      setTitleLabel,
      principalRef,
      checkboxSure,
      showVisible,
      visible,
      closeVisible,
    };
  },
});
