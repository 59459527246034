import { CrmFilterOptions, CrmTableOptions } from '@/types/type';
import { defineComponent, ref } from 'vue';
import {
  erpConfirm,
  errorMessage,
  successMessage,
} from '@/utils/publicMethods';
import { httpPost } from '@/api';
export default (Instance: any) => {
  const tableRef = ref();
  const filterOptions: CrmFilterOptions[] = [
    {
      type: 'input',
      prop: 'supplierName',
      value: '',
      placeholder: '请输入供应商名称',
    },
    {
      type: 'input',
      prop: 'country',
      value: '',
      placeholder: '请输入国家',
    },
    {
      type: 'input',
      prop: 'exportRecordNo',
      value: '',
      width: 200,
      placeholder: '请输入出口备案记录号',
    },
    {
      type: 'input',
      prop: 'principal',
      value: '',
      placeholder: '请输入负责人',
    },
    {
      type: 'multiSelect',
      prop: 'companyTypes',
      value: [],
      options: 'supplier_company_type',
      width: 180,
      placeholder: '请选择公司类型',
    },
  ];
  // defineComponent 调用示例
  // defineComponent({
  //   template: '<div>{{row.customerName}}</div>',
  //   props: ['row'],
  //   setup () {
  //     return { test, getCurrentDesc };
  //   }
  // })
  const columnList: CrmTableOptions[] = [
    {
      label: '供应商',
      prop: 'supplierName',
      width: 401,
    },
    {
      label: '国家',
      prop: 'countryName',
      width: 130,
    },
    {
      label: '出口备案记录号',
      prop: 'exportRecordNo',
      width: 170,
    },
    {
      label: '公司类型',
      prop: 'companyType',
    },
    {
      label: '负责人',
      prop: 'principal',
      width: 150,
    },
    {
      label: '创建人',
      prop: 'creator',
      width: 150,
    },
    {
      label: '创建时间',
      prop: 'createdTime',
      width: 182,
    },
    {
      label: '操作',
      component: defineComponent({
        template: `
          <div class="crm-btn-groups">
              <el-button type="primary" plain @click="edit(row)">编辑</el-button>
              <el-button type="info" plain @click="deleteRow(row)">删除</el-button>
          </div>
        `,
        props: ['row'],
        setup() {
          /** 编辑 */
          const edit = (row) => {
            Instance.setupState.rowData.value = row;
            Instance.setupState.setFormType(2);
            Instance.setupState.showVisible();
          };
          /** 删除 */
          const deleteRow = (row) => {
            Instance.setupState.rowData.value = row;
            erpConfirm('确定删除吗？')
              .then(async () => {
                const res = await httpPost('/malicrm/supplier/deleteSupplier', {
                  id: row.id,
                  version: row.version,
                });
                if (res.code === 200) {
                  successMessage('已删除');
                  Instance.setupState.refreshTable(true);
                } else {
                  errorMessage(res.message);
                }
              })
              .catch(() => {
                return false;
              });
          };
          return { edit, deleteRow };
        },
      }),
      prop: '',
      width: 206,
    },
  ];
  return { filterOptions, columnList, tableRef };
};
