
import useVisible from '@/hooks/useVisible';
import {
  defineComponent,
  PropType,
  reactive,
  ref,
  toRaw,
  toRefs,
  watch,
} from 'vue';
type CheckFormData = {
  checkedList: number[] | [];
  options: SupplierType[];
};
interface SupplierType {
  typeCategory: number;
  typeLabel?: string;
  typeValue: number;
  remark?: string | null;
  isRemark?: boolean;
}
export type SupplierName = 'business' | 'company' | 'qualification';
type SupplierTypeVos = Record<SupplierName, SupplierType[]>;

const supplierType: SupplierTypeVos = {
  business: [
    {
      typeCategory: 2,
      typeLabel: '牛肉',
      typeValue: 1,
      remark: null,
      isRemark: false,
    },
    {
      typeCategory: 2,
      typeLabel: '羊肉',
      typeValue: 2,
      remark: null,
      isRemark: false,
    },
    {
      typeCategory: 2,
      typeLabel: '鸡肉',
      typeValue: 3,
      remark: null,
      isRemark: false,
    },
    {
      typeCategory: 2,
      typeLabel: '猪肉',
      typeValue: 4,
      remark: null,
      isRemark: false,
    },
    {
      typeCategory: 2,
      typeValue: 5,
      typeLabel: '水产',
      remark: null,
      isRemark: false,
    },
    {
      typeCategory: 2,
      typeLabel: '其他',
      typeValue: 6,
      remark: '',
      isRemark: true,
    },
  ],
  company: [
    {
      typeCategory: 1,
      typeLabel: '生产商',
      typeValue: 1,
      remark: null,
      isRemark: true,
    },
    {
      typeCategory: 1,
      typeLabel: '贸易商',
      typeValue: 2,
      remark: null,
      isRemark: false,
    },
    {
      typeCategory: 1,
      typeLabel: '牧场',
      typeValue: 3,
      remark: null,
      isRemark: false,
    },
    {
      typeCategory: 1,
      typeLabel: '渔场',
      typeValue: 4,
      remark: null,
      isRemark: false,
    },
    {
      typeCategory: 1,
      typeLabel: '其他',
      typeValue: 5,
      remark: '',
      isRemark: true,
    },
  ],
  qualification: [
    {
      typeCategory: 3,
      typeValue: 1,
      typeLabel: 'OHSAS 18001',
      remark: null,
      isRemark: false,
    },
    {
      typeCategory: 3,
      typeValue: 2,
      typeLabel: 'ISO9001',
      remark: null,
      isRemark: false,
    },
    {
      typeCategory: 3,
      typeValue: 3,
      typeLabel: 'ISO14001',
      remark: null,
      isRemark: false,
    },
    {
      typeCategory: 3,
      typeValue: 4,
      typeLabel: 'HACCP',
      remark: null,
      isRemark: false,
    },
    {
      typeCategory: 3,
      typeValue: 5,
      typeLabel: 'Halal',
      remark: null,
      isRemark: false,
    },
    {
      typeCategory: 3,
      typeValue: 6,
      typeLabel: 'BRC',
      remark: null,
      isRemark: false,
    },
    {
      typeCategory: 3,
      typeLabel: 'Other',
      typeValue: 7,
      remark: '',
      isRemark: true,
    },
  ],
};

export default defineComponent({
  components: {},
  props: {
    placeholder: [String],
    /* 拼接字符串双向绑定 */
    modelValue: {
      type: [String],
    },
    /** 编辑回填值 */
    values: {
      type: [Array],
    },
    /** 供应商弹窗类型 */
    type: {
      type: String as PropType<SupplierName>,
      default: 'business',
    },
    title: String,
  },
  emits: ['close', 'sure', 'update:modelValue'],
  setup(props, ctx) {
    const InputRef = ref(); // input实例对象
    const { visible, showVisible, closeVisible } = useVisible();
    const inputValue = ref(''); // 拼接字符展示
    const formData: CheckFormData = reactive({
      checkedList: [],
      options: supplierType[props.type],
    });
    let restCheckList: SupplierType[] = [];
    // 弹窗定位展示
    const position = {
      left: '0px',
      top: '0px',
    };
    // 处理定位，可以加上位置动态偏移，这个没做
    const showBox = () => {
      const el = InputRef.value.$el.getBoundingClientRect();
      position.left = el.left + 'px';
      position.top = el.top + 62 + 'px';
      showVisible();
    };
    // 控制是否显示remark
    const isShowInput = (val: number) => {
      return (formData.checkedList as any).includes(val);
    };
    // 处理拼接名称
    const getInputValue = (data: SupplierType[]) => {
      let valueString = '';
      data.forEach((v, index) => {
        const isLast = data.length === index + 1;
        let str = v.typeLabel;
        if (v.remark && v.remark !== '') {
          str += isLast ? `(${v.remark})` : `(${v.remark})/`;
        } else {
          str += isLast ? '' : '/';
        }
        valueString += str;
      });
      return valueString;
    };
    // 提交处理最后给后端的数据
    const sure = () => {
      let data: SupplierType[] = [];
      if (formData.checkedList.length > 0) {
        const options = formData.options;
        const checkList = formData.checkedList;
        data = options.filter((v) =>
          checkList.find((check) => check === v.typeValue)
        );
        inputValue.value = getInputValue(data) as string;
      }
      ctx.emit('update:modelValue', inputValue);
      ctx.emit('sure', data, props.type);
      closeVisible();
    };
    const checkBoxChange = (e, index) => {
      console.log('e', e);
      if (!e) {
        formData.options[index].remark = null;
      }
      console.log('index', index);
    };
    const setDefaultCheckList = (data: SupplierType[]) => {
      formData.checkedList = data.map((v) => v.typeValue);
      data.forEach((v) => {
        // 找到选项的下标
        const index = formData.options.findIndex(
          (opt) => v.typeValue === opt.typeValue
        );
        formData.options[index].remark = v.remark;
      });
    };
    const close = () => {
      setDefaultCheckList(restCheckList);
      closeVisible();
    };
    // 监听后台返回的默认值
    watch(
      () => props.values as SupplierType[],
      (newValue: SupplierType[]) => {
        if (newValue && newValue.length > 0) {
          setDefaultCheckList(newValue as SupplierType[]);
          restCheckList = toRaw(props.values as SupplierType[]);
        }
      },
      { immediate: true }
    );

    // 监听拼接字符修改
    watch(
      () => props.modelValue,
      (newVal) => {
        inputValue.value = newVal as string;
      },
      { immediate: true }
    );
    return {
      close,
      sure,
      InputRef,
      visible,
      showVisible,
      closeVisible,
      inputValue,
      ...toRefs(formData),
      ...toRefs(position),
      showBox,
      isShowInput,
      checkBoxChange,
    };
  },
});
